/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import crud from '@/services/crud'

let {get, create} = crud('/media')

export {get, create}

export function upload(file, onProgress, config) {
  const form = new FormData()
  form.append('file', file)

  return create(form, {
    ...config,
    onUploadProgress(e) {
      if(typeof onProgress === 'function') {
        onProgress(Math.ceil(e.loaded / e.total * 100))
      }
    }
  })
}
