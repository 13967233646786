<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-file-input v-model="file" hide-details="auto" outlined dense small-chips truncate-length="15"
                prepend-icon="" color="secondary" :label="label" :loading="uploading"
                accept="image/jpeg, image/png, application/pdf" :error-messages="errorMessage" @change="upload"
  >
    <template v-if="required" v-slot:append>
      <v-icon small :color="file ? 'success' : 'red lighten-3'" class="pt-2">$warning</v-icon>
    </template>
    <template v-slot:progress>
      <v-progress-linear :value="progress" height="4" color="secondary" absolute />
    </template>
  </v-file-input>
</template>

<script>
  import {upload} from '@/modules/medias/api'
  import axios from 'axios'
  import config from '@/config'

  export default {
    name: 'MediaInput',
    props: {
      value: Object,
      label: String,
      required: Boolean
    },
    data() {
      return {
        uploading: false,
        cancelTokenSource: null,
        file: this.value ? new File([], this.value.originalName) : null,
        progress: 0,
        errorMessage: null
      }
    },
    computed: {
      link() {
        return this.value ? config.urls.api+ this.value['@id']+'.binary' : null
      },
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      async upload() {

        this.localValue = null
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel()
          this.cancelTokenSource = null
        }

        if (!this.file) {
          return
        }

        try {
          this.uploading = true
          this.progress = 0

          this.cancelTokenSource = axios.CancelToken.source()
          this.errorMessage = null
          this.localValue = (await upload(this.file, progress => {
            this.progress = progress
          }, {cancelToken: this.cancelTokenSource.token})).data

          this.uploading = false
        } catch (error) {
          this.file = null
          if(error.response && error.response.data && Array.isArray(error.response.data.violations) &&
            error.response.data.violations.some(violation => violation.code === 'unprocessable_media')) {
            this.errorMessage = 'Ce fichier n\'est pas supporté.'
          } else {
            this.errorMessage = 'Ce type de fichier n\'est pas supporté. Le fichier doit être au format jpeg, png ou pdf.'
          }
          if (axios.isCancel(error)) {
            return
          }

          this.uploading = false
          this.$root.$emit('error', error)
        }
      }
    }
  }
</script>

