<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-0">
    <v-form ref="form" lazy-validation>
      <v-card-title class="pb-2 px-0 pt-0 font-alt text-subtitle-1">Informations générales</v-card-title>
      <v-card-subtitle class="px-0 text-caption font-alt">
        Veuillez vérifier et compléter les informations renseignées avant de passer à l'étape suivante
      </v-card-subtitle>
      <patient-information-fields v-model="localValue" />
    </v-form>
  </v-card>
</template>

<script>
  import PatientInformationFields from '@/modules/patients/components/form/PatientInformationFields'

  export default {
    name: 'PatientInformation',
    components: {PatientInformationFields},
    props: {
      value: Object
    },
    data() {
      return {
        date: new Date().toISOString().substr(0, 10),
        sexOptions: [
          {text: '', value: 'U'},
          {text: 'Homme', value: 'M'},
          {text: 'Femme', value: 'F'}
        ]
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    }
  }
</script>
