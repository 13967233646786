<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" fullscreen transition="dialog-bottom-transition">
    <v-card tile class="fill-height">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ fieldResponse.field.label }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>$close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row class="ma-0 white" justify="center">
        <v-col cols="12" class="pa-11">
          <div v-if="fieldResponse.media.mimeType.startsWith('image')" class="media-wrapper">
            <v-img :src="src" max-width="900" class="ma-auto" />
          </div>
          <div v-else class="media-wrapper pdf">
            <object :data="src">Le document ne peut pas être affiché</object>
          </div>
        </v-col>
        <v-col v-if="fieldResponse.media.mimeType.startsWith('image')" cols="12" md="4" class="text-center">
          <v-btn small color="primary" class="elevation-0 font-alt" @click="download(fieldResponse.field.label)">
            Télécharger
          </v-btn>
        </v-col>
        <!--        <v-col cols="12" md="4" class="elevation-4" :class="$vuetify.breakpoint.mdAndDown ? 'd-none' : ''">-->
        <!--          <v-card-title class="font-alt text-subtitle-1 primary&#45;&#45;text">Validation du document</v-card-title>-->
        <!--          <v-card-subtitle class="font-weight-light text-caption font-alt secondary&#45;&#45;text">Vous pouvez valider le document si il est conforme au résultat attendu</v-card-subtitle>-->
        <!--          <v-card-actions v-if="unvalid" class="px-5">-->
        <!--            <v-btn small color="error" :loading="noloading">refuser</v-btn>-->
        <!--            <v-btn small color="secondary" :loading="yesloading" @click="validateDocument">valider</v-btn>-->
        <!--          </v-card-actions>-->
        <!--          <v-list-item v-else>-->
        <!--            <v-list-item-avatar size="64" class="ma-0">-->
        <!--              <font-awesome-icon :icon="['fad','check-circle']" size="3x" class="success&#45;&#45;text" />-->
        <!--            </v-list-item-avatar>-->
        <!--            <v-list-item-content>-->
        <!--              <v-list-item-title class="font-weight-light">Fichier validé</v-list-item-title>-->
        <!--              <v-list-item-subtitle class="text-caption font-alt">-->
        <!--                le fichier est conforme-->
        <!--              </v-list-item-subtitle>-->
        <!--            </v-list-item-content>-->
        <!--          </v-list-item>-->
        <!--        </v-col>-->
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  import config from '@/config'
  import axios from 'axios'
  import { slugify } from '@/utils/string'

  export default {
    name: 'MediaPreview',
    props: {
      fieldResponse: Object
    },
    data() {
      return {
        yesloading: false,
        noloading: false,
        valid: false,
        unvalid: true,
        notifications: false,
        sound: true,
        widgets: false
      }
    },
    computed: {
      url() {
        return config.urls.api + this.fieldResponse.media['@id']
      },
      src() {
        return `${this.url}.binary`
      }
    },
    methods: {
      validateDocument() {
        this.yesloading = true
        this.valid = true
        this.unvalid = false
        this.yesloading = false
      },
      forceFileDownload(response, title) {
        // TODO Replace this by an endpoint on api
        let ext
        switch (response.data.type) {
          case 'image/jpeg':
            ext = 'jpg'
            break
          case 'image/png':
            ext = 'png'
            break
          case 'application/pdf':
            ext = 'pdf'
            break
        }

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', slugify(title) + '.' + ext)
        document.body.appendChild(link)
        link.click()
      },
      async download(title) {
        try {
          const response = await axios({
            method: 'GET',
            url: this.url,
            responseType: 'blob',
            withCredentials: true
          })

          this.forceFileDownload(response, title)
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.media-wrapper {
  position: relative;
  width: 100%;
  &.pdf {
    height: 80vh !important;
  }

  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
  }
}
</style>
