<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div class="loader-pread d-flex align-center justify-center w-100 fill-height">
    <div class="logo mr-3">
      <v-img :src="require('@/assets/img/loader/maPread_icon_color.svg')" contain width="70" max-width="70" class="loader-img" />
    </div>
    <div class="mb-4">
      <v-img :src="$vuetify.theme.dark ? logoDark : logoLight" width="190" max-width="190" max-height="65" contain />
      <p class="font-alt text-body-2 text-loader">Veuillez patienter, chargement en cours</p>
    </div>
  </div>
</template>

<script>
  import logoLight from '@/assets/img/maPread_text_color.svg'
  import logoDark from '@/assets/img/maPread_text_light.svg'
  export default {
    name: 'Loader',
    data() {
      return {
        logoLight,
        logoDark
      }
    }
  }
</script>

<style lang="scss" scoped>
.loader-pread {
  color: #999;
  position: absolute;
  .loader-img {
    border-radius: 5px;
  }
  .text-loader {
    line-height: 1px;
  }
  .logo {
    div {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        display: block;
        content: '';
        width: 0;
        height: 0;
        background: rgba(255,255,255,.2);
        border-radius: 100%;
        transform: translate(-50%, -50%);
        opacity: 0;
        animation: circle 1.2s infinite;
      }
    }
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
</style>
