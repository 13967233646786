<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div>
    <v-card class="elevation-x pa-3">
      <v-card-title class="px-0 pt-0 pb-3 font-alt text-h6 font-weight-regular">Statut du dossier</v-card-title>
      <v-card-subtitle class="px-0 pb-0 mb-2 font-weight-light text-caption font-alt mb-2">
        Informations liées à votre admission.
      </v-card-subtitle>
      <v-list-item class="pa-0">
        <v-list-item-avatar size="64" class="mr-1">
          <font-awesome-icon :icon="['fad',validated ? 'check-circle' : 'pause-circle']" size="3x"
                             :class="validated ? 'success--text' : 'orange--text text--darken-2'"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-regular mb-0">
            {{ validated ? 'Dossier Validé' : 'En cours' }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-0">
            {{ validated ? 'Votre admission est validée' : 'Dossier en cours' }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-card class="elevation-x mt-5 pa-3">
      <v-card-title class="px-0 pt-0 pb-3 font-alt text-h6 font-weight-regular">Complétion du dossier</v-card-title>
      <v-card-subtitle class="px-0 font-weight-light text-caption font-alt mb-2">
        Taux de complétion du dossier
      </v-card-subtitle>
      <v-card-text class="px-2">
        <admission-status-step class="mb-2" label="Informations" :conditions="patientInformationConditions" />
        <admission-status-step class="mb-2" label="Questionnaire" :conditions="surveyConditions" />
        <admission-status-step label="Documents" :conditions="documentsConditions" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {empty} from '@/utils/variables'
  import AdmissionStatusStep from '@/modules/patients/components/AdmissionStatusStep'

  export default {
    name: 'AdmissionStatus',
    components: {AdmissionStatusStep},
    props: {
      surveyFields: Array,
      surveyModel: Object,
      patientModel: Object,
      admissionStatus: String
    },
    computed: {
      validated() {
        return this.admissionStatus === 'validated'
      },
      patientInformationConditions() {
        return [
          !empty(this.patientModel.firstName),
          !empty(this.patientModel.lastName) || !empty(this.patientModel.birthName),
          !empty(this.patientModel.birthDate)
        ]
      },
      requiredSurveyFields() {
        return this.surveyFields.filter(field => field.required)
      },
      surveyConditions() {
        return this.requiredSurveyFields.filter(field => field.type !== 'document').map(field => {
          return !empty(this.surveyModel[field['@id']])
        })
      },
      documentsConditions() {
        return this.requiredSurveyFields.filter(field => field.type === 'document').map(field => {
          return !empty(this.surveyModel[field['@id']])
        })
      }
    }
  }
</script>
