<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog v-model="localValue" max-width="660">
    <v-card :class="color">
      <v-icon small class="close-submit-dialog" @click.stop="localValue = false">$close</v-icon>
      <v-list-item class="px-3 py-2" :class="actions ? 'pb-0' : ''">
        <v-img v-if="$vuetify.breakpoint.smAndUp" :src="img" max-width="150px" />
        <v-list-item-content class="ml-2">
          <v-list-item-title class="text-h4 font-weight-light color-title">{{ title }}</v-list-item-title>
          <v-list-item-subtitle class="subtitle-text text-body-2 font-alt mb-1 lh-sm">
            {{ subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions v-if="actions" class="pt-0 justify-end">
        <v-spacer />
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      actions: Boolean,
      title: String,
      subtitle: String,
      img: String,
      value: Boolean,
      color: String
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>
