<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-0">
    <v-form ref="form" lazy-validation>
      <template v-for="fieldSet in sortedFieldsets(fieldSets)">
        <div :key="fieldSet['@id']" class="mb-6">
          <v-card-title class="pb-2 px-0 pt-0 font-alt text-subtitle-1">{{ fieldSet.title }}</v-card-title>
          <v-card-subtitle class="px-0 font-weight-light text-caption font-alt">
            {{ fieldSet.subtitle }}
          </v-card-subtitle>

          <v-row>
            <v-col v-for="field in fields(fieldSet)" :key="field['@id']" cols="12" md="6">
              <text-field v-if="field.type === 'string'" v-model="localValue[field['@id']]"
                          :error-icon="field.required"
                          :label="field.required ? field.label + ' (requis)' : field.label"
                          :error="!localValue[field['@id']]"
              />
              <v-select v-else-if="field.type === 'choice'"
                        v-model="localValue[field['@id']]"
                        color="secondary" outlined dense hide-details="auto" clearable
                        :label="field.required === true ? field.label + ' (requis)' : field.label "
                        :items="field.choices"
                        item-text="label"
                        item-value="id"
              >
                <template v-if="field.required" v-slot:append>
                  <v-icon small :color="localValue[field['@id']] ? 'success' : 'red lighten-3'" class="pt-2">
                    $warning
                  </v-icon>
                </template>
              </v-select>
              <date-picker v-else-if="field.type === 'date'"
                           v-model="localValue[field['@id']]" :error-icon="field.required"
                           :label="field.required === true ? field.label + ' (requis)' : field.label"
                           :error="!localValue[field['@id']]"
              />
              <v-select v-else-if="field.type === 'boolean'" v-model="localValue[field['@id']]"
                        color="secondary" outlined dense hide-details="auto" clearable
                        :items="['Oui', 'Non']"
                        :label="field.required === true ? field.label + ' (requis)' : field.label "
              >
                <template v-if="field.required" v-slot:append>
                  <v-icon small :color="localValue[field['@id']] ? 'success' : 'red lighten-3'" class="pt-2">
                    $warning
                  </v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-form>
  </v-card>
</template>

<script>
  import DatePicker from '@/modules/core/components/DatePicker'
  import TextField from '@/modules/core/components/TextField'

  export default {
    name: 'Survey',
    components: {TextField, DatePicker},
    props: {
      fieldSets: Array,
      value: Object
    },
    data() {
      return {
        now: new Date().toISOString().substr(0, 10)
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      fields() {
        return fieldSet => fieldSet.fields.filter(field => field.type !== 'document').sort(function(a,b){
          return a.position - b.position
        })
      },
      sortedFieldsets(){
        return fieldSets => fieldSets.sort(function(a,b){
          return a.position - b.position
        })
      }
    }
  }
</script>

