<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-list-item class="px-0">
    <v-list-item-avatar>
      <v-progress-circular :rotate="270" :size="40" :width="4" :value="completion"
                           :color="completionToColor(completion)"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ label }}  <span :class="'font-weight-bold '+ completionToColor(completion)+'--text'">- {{ completion }}%</span>
      </v-list-item-title>
      <v-list-item-subtitle :class="completionToColor(completion)+'--text'">
        <template v-if="isCompleted">
          Complet
        </template>
        <template v-else>
          {{ unfilled }} {{ 'élément'|pluralize(unfilled) }} {{ 'manquant'|pluralize(unfilled) }}
        </template>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import dataDisplayers from '@/mixins/dataDisplayers'
  export default {
    name: 'AdmissionStatusStep',
    mixins: [dataDisplayers],
    props: {
      label: String,
      conditions: Array
    },
    computed:{
      isCompleted() {
        return this.conditions.filter(Boolean).length === this.conditions.length
      },
      completion() {
        return this.conditions.length > 0 ? Math.floor((this.conditions.filter(Boolean).length / this.conditions.length) * 100) : 100
      },
      unfilled() {
        return this.conditions.length - this.conditions.filter(Boolean).length
      }
    }
  }
</script>
