<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <span v-if="field.type === 'birthdate'" class="font-alt text-caption font-weight-bold">
    {{ $dayjs(value).format('DD/MM/YYYY') || 'Non renseignée' }}
  </span>
  <span v-else-if="field.type === 'date'" class="font-alt text-caption font-weight-bold">
    {{ $dayjs(value).format('LL') || 'Non renseignée' }}
  </span>
  <span v-else-if="field.type === 'datetime'" class="font-alt text-caption font-weight-bold">
    {{ $dayjs(value).format('LLL') || 'Non renseignée' }}
  </span>
  <span v-else-if="field.type === 'choice'" class="font-alt text-caption font-weight-bold">
    {{ choiceText || 'Non renseignée' }}
  </span>
  <span v-else class="font-alt text-caption font-weight-bold">
    {{ value || 'Non renseignée' }}
  </span>
</template>

<script>
  export default {
    name: 'FieldValue',
    props: {
      field: {
        type: Object,
        required: true
      },
      value: {
        required: true
      }
    },
    computed: {
      choiceText() {
        let choice = this.field.options.find(option => option.value === this.value)
        return choice ? choice.text : ''
      }
    }
  }
</script>

<style scoped>

</style>
