<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-0">
    <v-form ref="form" lazy-validation>
      <template v-for="fieldSet in sortedFieldsets(fieldSets)">
        <div :key="fieldSet['@id']" class="mb-6">
          <v-card-title class="pb-2 px-0 pt-0 font-alt text-subtitle-1">{{ fieldSet.title }}</v-card-title>
          <v-card-subtitle class="px-0 font-weight-light text-caption font-alt">
            {{ fieldSet.subtitle }}
          </v-card-subtitle>

          <v-row>
            <v-col v-for="field in fields(fieldSet)" :key="field['@id']" cols="12" xl="6">
              <media-input v-model="localValue[field['@id']]"
                           :label="field.required ? field.label + ' (requis)' : field.label "
                           :required="field.required"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </v-form>
  </v-card>
</template>

<script>
  import MediaInput from '@/modules/medias/components/MediaInput'

  export default {
    name: 'Documents',
    components: {MediaInput},
    props: {
      fieldSets: Array,
      value: Object
    },
    data() {
      return {
        rectoIdentity: null,
        versoIdentity: null,
        vitalCard: null,
        rectoHealthComplement: null,
        versoHealthComplement: null,
        e111: null,
        cfe: null,
        workAccident: null,
        notebookHealth: null,
        cmu: null
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      fields() {
        return fieldSet => fieldSet.fields.filter(field => field.type === 'document').sort(function(a,b){
          return a.position - b.position
        })
      },
      sortedFieldsets(){
        return fieldSets => fieldSets.sort(function(a,b){
          return a.position - b.position
        })
      }
    }
  }
</script>
