<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-0">
    <v-card-title class="pb-1 px-0 pt-0 font-alt text-subtitle-1">Envoi du dossier</v-card-title>
    <v-card-subtitle class="text-caption font-alt px-0 py-2 ">
      Vous êtes sur le point d'envoyer votre dossier d'admission.
    </v-card-subtitle>

    <v-alert type="info" dense text border="left" class="text-caption font-alt">
      Votre dossier d'admission peut être envoyé malgré l'absence de certains élements. Vous pouvez modifier et compléter votre dossier jusqu'au jour de votre admission dans l'établissement.
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <div class="mb-2">
          <font-awesome-icon :icon="['fad', 'check-circle']" class="mr-2 secondary--text" size="lg" />
          <span class="subtitle-1 font-alt secondary--text">Informations du patient :</span>
        </div>
        <div v-for="(field, key) in patientFields" :key="key" class="pl-8">
          <span class="font-alt font-weight-light text-subtitle-2">{{ field.label }} :</span>
          <field-value :field="field" :value="field.value" />
        </div>
      </v-col>
      <v-col v-if="requiredFieldSets.length" cols="12" md="6">
        <div class="mb-2">
          <font-awesome-icon :icon="['fad', 'times-circle']" class="mr-2 error--text text--lighten-2" size="lg" />
          <span class="subtitle-1 font-alt error--text text--lighten-2">Éléments manquants :</span>
        </div>

        <div v-for="fieldSet in requiredFieldSets" :key="fieldSet.id" class="flex-column d-flex pl-8 pb-4">
          <span class="font-alt font-weight-light text-subtitle-2"> {{ fieldSet.title }} :</span>
          <span v-for="field in requiredFieldSetFields(fieldSet)" :key="field.id"
                class="font-alt text-caption font-weight-bold"
          >
            {{ field.label }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { empty } from '@/utils/variables'
  import { flatten } from '@/utils/object'
  import patientModel from '@/models/patient'
  import FieldValue from '@/modules/core/components/FieldValue'

  export default {
    name: 'AdmissionFormSummary',
    components: {FieldValue},
    props: {
      form: Object,
      model: Object
    },
    computed: {
      combineModelAndValue() {
        return (fields, value) => {
          let result = {}
          Object.entries(fields).forEach(([key, field]) => {
            if (field.type === 'form') {
              result[key] = {...field, children: this.combineModelAndValue(field.children, value[key])}
            } else {
              result[key] = {...field, value: value[key]}
            }
          })
          return result
        }
      },
      patientFields() {
        let fields = this.combineModelAndValue(patientModel, this.model.patient)

        return flatten(fields, (key, value, push, flatten) => {
          value.type === 'form' ? flatten(value.children) : push()
        })
      },
      requiredFieldSets() {
        return this.form && this.form.fieldSets ? this.form.fieldSets.filter(fieldSet => {
          return this.requiredFieldSetFields(fieldSet).length > 0
        }) : []
      },
      requiredFieldSetFields() {
        return fieldSet => fieldSet.fields.filter(field => field.required && empty(this.model.form[field['@id']]))
      }
    }
  }
</script>
